import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FilterState, ProductDatabaseFilters } from "../types";

type SetFilterPayload = {
  key: keyof ProductDatabaseFilters;
  value:
    | ProductDatabaseFilters[keyof ProductDatabaseFilters]
    | { startDate: any; endDate: any };
};

const initialState: ProductDatabaseFilters = {
  searchTerm: "",
  launchDateForShop: { startDate: "", endDate: "" },
  stockStatus: "",
  priceRange: { min: 0, max: 0 },
  salesVolume: { min: 0, max: 0 },
  domainTLD: ".com",
  storeCurrency: "USD",
  storeLanguage: "en",
  selectedCategories: [],
  keywordFilters: {
    includeTitleKeywords: [],
    excludeTitleKeywords: [],
    includeDescriptionKeywords: [],
    excludeDescriptionKeywords: [],
  },
};

const productsDatabaseFiltersSlice = createSlice({
  name: "productsDatabaseFilters",
  initialState,
  reducers: {
    resetFilters: () => initialState,
    loadFilters: (
      state,
      action: PayloadAction<Partial<ProductDatabaseFilters>>
    ) => {
      state.keywordFilters =
        action.payload.keywordFilters || initialState.keywordFilters;
      state.priceRange = action.payload.priceRange || initialState.priceRange;
      state.salesVolume =
        action.payload.salesVolume || initialState.salesVolume;
      state.domainTLD = action.payload.domainTLD || initialState.domainTLD;
      state.storeCurrency =
        action.payload.storeCurrency || initialState.storeCurrency;
      state.storeLanguage =
        action.payload.storeLanguage || initialState.storeLanguage;
      state.launchDateForShop =
        action.payload.launchDateForShop || initialState.launchDateForShop;
    },
    setFilter: (state, action: PayloadAction<SetFilterPayload>) => {
      const { key, value } = action.payload;
      (state[key] as typeof value) = value;
    },
    selectAllCategories: (state, action) => {
      state.selectedCategories = action.payload;
    },
    toggleCategory: (state, action: PayloadAction<string>) => {
      const category = action.payload;
      const index = state.selectedCategories.indexOf(category);

      if (index === -1) {
        state.selectedCategories.push(category);
      } else {
        state.selectedCategories.splice(index, 1);
      }
    },
    setKeywords: (
      state,
      action: PayloadAction<{
        key: keyof FilterState["keywordFilters"];
        value: string[];
      }>
    ) => {
      state.keywordFilters[action.payload.key] = action.payload.value;
    },
  },
});

export const {
  setFilter,
  toggleCategory,
  setKeywords,
  selectAllCategories,
  loadFilters,
  resetFilters,
} = productsDatabaseFiltersSlice.actions;
export default productsDatabaseFiltersSlice.reducer;
